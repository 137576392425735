<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="6"
        offset-md="6"
        class="pa-2 d-flex align-center justify-end"
      >
        <v-btn icon class="mr-3" @click="toggleSearch = !toggleSearch">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="toggleSearch" cols="12" class="pa-2 pt-0">
        <v-text-field
          v-model="search"
          :label="$t('actions.search')"
          hide-details
          width="100%"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="pa-2 d-flex flex-column">
        <v-card height="100%" class="d-flex flex-column elevation-3 pa-2">
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :custom-filter="usersFilter"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :no-data-text="$t('helpers.noData')"
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.devices`]="{ item }">{{ getUnitsNames(item.devices) }}</template>
          </v-data-table>
          <v-spacer />
          <div class="text-center ml-auto pt-2">
            <v-pagination v-model="page" circle :length="pageCount" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { filters } from '@/mixins'

const dayjs = require('dayjs')
require('dayjs/locale/es')
dayjs.locale('es')

export default {
  mixins: [filters],
  data () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      isEditting: false,
      object: {},
      dialog: false,
      search: '',
      toggleSearch: false
    }
  },
  computed: {
    ...mapState(['isDark', 'wialonUsers']),

    headers () {
      return [
        {
          text: this.$i18n.t('users.name'),
          align: 'center',
          sortable: true,
          value: 'name'
        },
        { text: this.$i18n.t('users.username'), value: 'username' },
        { text: this.$i18n.t('users.email'), value: 'email', sortable: false },
        { text: this.$i18n.t('users.units'), value: 'devices', sortable: false }
      ]
    },

    data () {
      return this.wialonUsers.list
    }
  },
  methods: {
    getUnitsNames (units) {
      return units.map(unit => { return unit.ident }).join(', ')
    }
  }
}
</script>
