import LoginLayout from '@/layouts/Login.vue'
import AppLayout from '@/layouts/Main.vue'

import NotFound from '@/views/NotFound.vue'
import Login from '@/views/Login.vue'
import Recovery from '@/views/Recovery.vue'
import Home from '@/views/Home.vue'
import Profile from '@/views/Profile.vue'
import Accounts from '@/views/Accounts.vue'
import Dealers from '@/views/Dealers.vue'
import Whitelabels from '@/views/Whitelabels.vue'
import WialonUsers from '@/views/WialonUsers.vue'
import Devices from '@/views/Devices.vue'
import Configuration from '@/views/Configuration.vue'
import ShopifyConfiguration from '@/views/ShopifyConfiguration.vue'

export default [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/',
    component: LoginLayout,
    children: [
      {
        path: 'Login',
        name: 'login',
        components: { default: Login }
      },
      {
        path: 'Recovery',
        name: 'recovery',
        components: { default: Recovery }
      }
    ]
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: 'Home',
        name: 'home', /* IF YOU USE Alpha-i18n, please use any existing identifier */
        components: { default: Home }
      },
      {
        path: 'Profile',
        name: 'profile',
        components: { default: Profile }
      },
      {
        path: 'Accounts',
        name: 'accounts', /* IF YOU USE Alpha-i18n, please use any existing identifier */
        components: { default: Accounts }
      },
      {
        path: 'Dealers',
        name: 'dealers', /* IF YOU USE Alpha-i18n, please use any existing identifier */
        components: { default: Dealers }
      },
      {
        path: 'Whitelabels',
        name: 'whitelabels', /* IF YOU USE Alpha-i18n, please use any existing identifier */
        components: { default: Whitelabels }
      },
      {
        path: 'Users',
        name: 'users', /* IF YOU USE Alpha-i18n, please use any existing identifier */
        components: { default: WialonUsers }
      },
      {
        path: 'Devices',
        name: 'devices', /* IF YOU USE Alpha-i18n, please use any existing identifier */
        components: { default: Devices }
      }
    ]
  },
  {
    path: '/Settings',
    component: AppLayout,
    children: [
      {
        path: 'PodIOTSuites',
        name: 'pod.configuration', /* IF YOU USE Alpha-i18n, please use any existing identifier */
        components: { default: Configuration }
      },
      {
        path: 'Shopify',
        name: 'shopify.configuration', /* IF YOU USE Alpha-i18n, please use any existing identifier */
        components: { default: ShopifyConfiguration }
      }
    ]
  },
  {
    path: '*',
    component: NotFound
  }
]