/*
  Dealers actions
*/
// GraphQL initializer
// graphql files
// Libraries

import list from 'GraphQL/queries/dealers/list.gql'
import wialonUsersList from 'GraphQL/queries/wialonUsers/list.gql'
import { apollo } from '@/plugins/apollo'

export default {
  async getList ({ state, dispatch, commit, rootState }, payload = false) {
    if (payload) {
      commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: list,
      variables: {
        token: rootState.users.entity.token
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, result } = response.data.dealers

      switch (status) {
        case 'OK':
          commit('setDealers', result)
          break

        case 'UNPROCESSABLE':
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      if (payload) {
        commit('setLoading', false, { root: true })
      }
    })
  },

  async getWialonUsersList ({ state, dispatch, commit, rootState }, payload = false) {
    if (payload) {
      commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: wialonUsersList,
      variables: {
        token: rootState.users.entity.token
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, result } = response.data.wialonUsers

      switch (status) {
        case 'OK':
          commit('setWialonUsers', result)
          break

        case 'UNPROCESSABLE':
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      if (payload) {
        commit('setLoading', false, { root: true })
      }
    })
  }
}
