import cookies from 'js-cookie'

export default {
  setSidebar (state, payload) {
    state.sidebar = payload
  },

  setCountries (state, payload) {
    state.countries = payload
  },

  setHardwareModels (state, payload) {
    state.hardwareModels = payload
  },

  setLanguage (state, payload) {
    state.language = payload

    cookies.set('language', state.language)
  },

  toggleDark (state) {
    state.isDark = !state.isDark

    cookies.set('dark', state.isDark)
  },

  setDark (state, payload) {
    state.isDark = payload

    cookies.set('dark', state.isDark)
  },

  toggleDrawer (state) {
    state.drawer = !state.drawer
  },

  contractDrawer (state) {
    state.drawer = false
  },

  setDrawer (state, payload) {
    state.drawer = payload
  },

  setLoading (state, payload) {
    state.isLoading = payload
  },

  setIsWhitelabelLoading (state, payload) {
    state.isWhitelabelLoading = payload
  },

  setWhitelabel (state, payload) {
    state.whitelabel = payload
  },

  setCookies (state, payload) {
    cookies.set('state', !payload)
    state.isCookies = !payload
  },

  setMini (state, payload) {
    state.isMini = payload

    cookies.set('mini', state.isMini)
  },

  setWidth (state, payload) {
    if (payload < 930) {
      state.width = {
        full: payload,
        dialog: payload
      }
    } else {
      state.width = {
        full: payload,
        dialog: payload * 0.8333333333
      }
    }
  },

  toggleSnackbar (state, payload) {}
}