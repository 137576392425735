<template>
  <v-container fluid>
    <v-layout row wrap class="px-8 mt-5">
      <h1>{{ $t("pages.shopify.configuration") }}</h1>
      <v-flex xs12>
        <v-divider width="600" class="my-2" />
      </v-flex>

      <v-flex xs12 class="pa-2">
        <v-card>
          <v-card-text>
            <v-layout row wrap class="px-4">
              <v-flex xs12 class="px-2 my-5">
                <h4>{{ $t("shopify.configuration.categories.auth") }}</h4>
                <v-divider width="400" />
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  v-model="object.shopName"
                  :label="$t('shopify.configuration.fields.shopName')"
                  :placeholder="$t('shopify.configuration.hints.shopName')"
                  :color="color"
                  :errors="errors.shopName.length > 0"
                  :error-messages="errors.shopName"
                  outlined
                />
              </v-flex>

              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  v-model="object.accessToken"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('shopify.configuration.fields.accessToken')"
                  :placeholder="$t('shopify.configuration.hints.accessToken')"
                  :color="color"
                  :errors="errors.accessToken.length > 0"
                  :error-messages="errors.accessToken"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  outlined
                  @click:append="showPassword = !showPassword"
                />
              </v-flex>

              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  v-model="object.webhookToken"
                  :type="showWebhookToken ? 'text' : 'password'"
                  :label="$t('shopify.configuration.fields.webhookToken')"
                  :placeholder="$t('shopify.configuration.hints.webhookToken')"
                  :color="color"
                  :errors="errors.webhookToken.length > 0"
                  :error-messages="errors.webhookToken"
                  :append-icon="showWebhookToken ? 'mdi-eye-off' : 'mdi-eye'"
                  outlined
                  @click:append="showWebhookToken = !showWebhookToken"
                />
              </v-flex>

              <v-flex xs12 class="px-2 d-flex justify-end">
                <v-btn :disabled="isLoading" depressed color="primary" @click="submit">
                  {{ $t('actions.save') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import gql from 'graphql-tag'

export default {
  data () {
    return {
      object: {},
      rawErrors: {},
      showPassword: false,
      showWebhookToken: false,
      isLoading: false,
      randomNumber: 0
    }
  },

  computed: {
    ...mapState(['users']),
    ...mapGetters(['color']),

    defaultObject () {
      if (this.users.entity.loaded) {
        return {
          id: this.users.entity.shopifyConfiguration.id,
          shopName: this.users.entity.shopifyConfiguration.shopName,
          accessToken: this.users.entity.shopifyConfiguration.accessToken,
          webhookToken: this.users.entity.shopifyConfiguration.webhookToken
        }
      }
      return {
        id: null,
        shopName: '',
        accessToken: '',
        webhookToken: ''
      }
    },

    errors () {
      const errors = {}

      for (const key in this.defaultObject) {
        let keyCamel = key
        if (key.includes(' ')) {
          keyCamel = this.convertToCamelCase(key)
        }

        if (this.rawErrors[key] === undefined) {
          errors[keyCamel] = ''
        } else {
          errors[keyCamel] = this.rawErrors[key].join(', ')
        }
      }

      return errors
    }
  },

  watch: {
    defaultObject (newVal, oldVal) {
      this.object = Object.assign({ accessToken: '' }, newVal)
    }
  },

  beforeMount () {
    this.object = Object.assign({ accessToken: '' }, this.defaultObject)
  },

  methods: {
    convertToCamelCase (str) {
      str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, (match) => {
        return match.charAt(match.length - 1).toUpperCase()
      })
      return str.charAt(0).toLowerCase() + str.substring(1)
    },

    submit () {
      this.isLoading = true
      this.rawErrors = {}
      this.$apollo.mutate({
        mutation: gql`
          mutation ($data: ShopifyConfigurationInput!, $token: String!) {
            editShopifyConfiguration(token: $token, data: $data) {
              status
              errors
              result {
                id
                shopName
                accessToken
                webhookToken
              }
            }
          }
        `,
        variables: {
          data: this.object,
          token: this.users.entity.token
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        this.isLoading = false
        const { status, errors } = response.data.editShopifyConfiguration
        const rawErrors = {}

        switch (status) {
          case 'OK':
            // this.object = Object.assign({}, this.defaultObject)
            this.$store.dispatch('users/loadEntity', true)
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('shopify.configuration.saved'),
              color: 'green darken-2',
              duration: 10000
            })
            // this.$router.push('/Home')
            break
          case 'UNPROCESSABLE':
            this.$store.commit('toggleSnackbar', {
              mesasge: this.$i18n.t('errors.invalidFields'),
              color: 'orange darken-2'
            })

            for (const key in errors) {
              rawErrors[this.convertToCamelCase(key)] = errors[key]
            }
            this.rawErrors = rawErrors
            break
          default:
            this.$store.commit('toggleSnackbar')
            break
        }
      }).catch((err) => {
        this.isLoading = false
        console.log(err)
        this.$store.commit('toggleSnackbar')
      })
    }
  }
}
</script>
