export default {
  countries: [],
  hardwareModels: [],

  // Dashboard operations variables
  changelog: false,
  isCookies: false,
  isLoading: true,
  isMini: false,
  drawer: false,
  isDark: false,

  whitelabel: {},
  isWhitelabelLoading: true,

  language: 'en',

  width: {
    full: window.innerWidth,
    dialog: window.innerWidth * 0.8333333333 // 10 of 12 columns
  }
}
