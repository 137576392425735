export default {
  methods: {
    usersFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      const scan1 = item.username.toLowerCase().includes(search.toLowerCase())
      const scan2 = item.email.toLowerCase().includes(search.toLowerCase())
      return scan0 || scan1 || scan2
    },

    customersFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      const scan1 = item.username.toLowerCase().includes(search.toLowerCase())
      const scan2 = item.email.toLowerCase().includes(search.toLowerCase())
      return scan0 || scan1 || scan2
    },

    accountsFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      return scan0
    },

    dealersFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      return scan0
    },

    devicesFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      const scan1 = item.ident.toLowerCase().includes(search.toLowerCase())
      const scan2 = item.hardwareModel.name.toLowerCase().includes(search.toLowerCase())
      const scan3 = item.phoneNumber.toLowerCase().includes(search.toLowerCase())
      return scan0 || scan1 || scan2 || scan3
    },

    coursesFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      return scan0
    },

    zonesFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      return scan0
    },

    pointsOfInterestFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      return scan0
    },

    gamesFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      return scan0
    },

    whitelabelsFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      return scan0
    }
  }
}
