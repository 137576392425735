<template>
  <v-col class="d-flex justify-center align-center">
    <v-row
      no-gutters
      align="center"
      justify="center"
      class="pa-4"
    >
      <v-col cols="12" md="5" lg="4" xl="3" class="px-2">
        <v-card class="pb-5">
          <v-card-title class="text-center">
            <template v-if="hasWhitelabel">
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; flex: 1;">
                <img :src="logo" height="50">
                <v-divider :dark="isDark" vertical class="ma-2" />
                <div style="display: flex; flex-direction: column; align-items: flex-start;">
                  <h3 class="pa-0 ma-0 text-left" style="line-height: 0.9;">
                    {{ whitelabel.name }}
                  </h3>
                </div>
              </div>
            </template>

            <template v-else-if="!isWhitelabelLoading">
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; flex: 1;">
                <img :src="isDark ? $assets.faviconWhite : $assets.favicon" height="50">
                <v-divider :dark="isDark" vertical class="ma-2" />
                <div style="display: flex; flex-direction: column; align-items: flex-start;">
                  <h3 class="pa-0 ma-0 text-left" style="line-height: 0.9;">
                    {{ $t('app.site') }}
                  </h3>
                </div>
              </div>
            </template>

            <template v-else>
              <v-sheet height="50" />
            </template>
          </v-card-title>

          <v-card-text>
            <v-row no-gutters class="px-5 mt-5">
              <v-col cols="12" class="mb-5">
                <v-text-field
                  v-model="credentials.username"
                  :label="$t('users.username')"
                  outlined
                  hide-details
                  @keydown.enter="handleLogin"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="credentials.password"
                  :label="$t('users.password')"
                  outlined
                  type="password"
                  hide-details
                  @keydown.enter="handleLogin"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="px-9 mt-2">
            <v-row no-gutters>
              <v-col cols="12" class="d-flex">
                <!-- <v-btn
                  v-if="!hasWhitelabel"
                  depressed
                  text
                  color="red darken-2"
                  to="/Recovery"
                >
                  {{ $t('recovery.title') }}
                </v-btn> -->

                <v-btn
                  depressed
                  large
                  color="primary"
                  outlined
                  class="flex"
                  @click="handleLogin"
                >
                  {{ $t('users.signIn') }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="mt-5">
                <v-select
                  v-model="language"
                  :items="availableLanguages"
                  :label="$t('languages.select')"
                  outlined
                  dense
                  color="primary"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      credentials: {
        username: '',
        password: ''
      }
    }
  },

  computed: {
    ...mapState(['width', 'whitelabel', 'isDark']),

    color () {
      if (this.isDark) {
        return 'white black--text'
      }

      return 'primary white--text'
    },

    language: {
      get () {
        return this.$store.state.language
      },

      set (value) {
        this.$store.commit('setLanguage', value)
      }
    },

    hasWhitelabel () {
      if (this.whitelabel.logo === undefined) {
        return false
      }

      return true
    },

    isWhitelabelLoading () {
      return this.$store.state.isWhitelabelLoading
    },

    logo () {
      if (this.whitelabel.logo === undefined) {
        return this.$assets.logo
      }

      return this.whitelabel.logo
    },

    availableLanguages () {
      return [
        { text: this.$i18n.t('languages.availableLanguages.es'), value: 'es' },
        { text: this.$i18n.t('languages.availableLanguages.en'), value: 'en' }
      ]
    }
  },

  watch: {
    language (v) {
      this.$i18n.locale = v
    }
  },

  mounted () {
    this.$store.commit('setLoading', false)
  },

  created () {
    // this.$store.dispatch('protocols/getList', false, { root: true })
  },

  methods: {
    handleLogin () {
      this.$store.dispatch('users/login', this.credentials)
    }
  }
}
</script>