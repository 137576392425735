export default {
  setAccountChartData (state, payload) {
    state.accountChartData = []
    state.accountChartData = payload
  },

  setUnitChartData (state, payload) {
    state.unitChartData = []
    state.unitChartData = payload
  }
}