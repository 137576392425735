<template>
  <v-navigation-drawer
    v-model="drawer"
    color="primary"
    dark
    overflow
    app
    mobile-break-point="930"
    :temporary="isMobile"
    :permanent="!isMobile"
    :mini-variant="isMini"
    :expand-on-hover="isMini"
    class="elevation-5"
  >
    <!-- Sidebar header -->
    <v-list-item two-line>
      <v-list-item-avatar>
        <v-avatar height="40" contain>
          <v-img :src="logo" contain />
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-title>
        {{ appName }}
      </v-list-item-title>
    </v-list-item>

    <!-- /Sidebar header -->

    <v-divider class="mx-3 mb-2" />

    <!-- Sidebar user -->
    <v-list-group>
      <template v-slot:activator>
        <v-list-item-avatar>
          <v-avatar :color="`${isDark ? 'secondary' : 'white black--text'}`">
            {{ avatar }}
          </v-avatar>
        </v-list-item-avatar>

        <v-list-item-title class="white--text text-truncate">
          {{ fullName }}
        </v-list-item-title>
      </template>

      <v-list-item two-line @click="$store.dispatch('users/logout')">
        <v-list-item-avatar>
          <v-icon>mdi-lock</v-icon>
        </v-list-item-avatar>
        
        <v-list-item-title>
          {{ $t('users.signOut') }}
        </v-list-item-title>
      </v-list-item>
    </v-list-group>
    <!-- /Sidebar user -->

    <v-divider class="mx-3 my-1" />
  
    <!-- Sidebar options -->
    <v-list shaped dense dark>
      <v-list-item
        v-for="(link, i) in links"
        :key="`link-${i}`"
        :to="link.path"
        class="my-1"
        :active-class="`${isDark ? 'selected-sidebar-dark' : 'selected-sidebar'}`"
      >
        <v-list-item-avatar>
          <v-icon size="30">{{ link.icon }}</v-icon>
        </v-list-item-avatar>

        <v-list-item-title style="font-size: 0.9em">
          {{ link.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <!-- /Sidebar options -->

    <v-spacer />

    <!-- Sidebar tools -->

    <!-- <template v-if="!isMobile" v-slot:append>
      <v-divider class="mb-1 mx-3" />

      <v-list-item @click="$store.commit('setMini', !isMini)">
        <v-list-item-avatar>
          <v-icon>mdi-chevron-double-{{ isMini ? 'right' : 'left' }}</v-icon>
        </v-list-item-avatar>

        <v-list-item-title>
          {{ $t(`actions.sidebar.${isMini ? 'expand' : 'contract'}`) }}
        </v-list-item-title>
      </v-list-item>
    </template> -->
    
    <!-- /Sidebar tools -->
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState(['users', 'isMini', 'isDark', 'whitelabel']),
    ...mapGetters({
      avatar: 'users/avatar',
      fullName: 'users/fullName'
    }),
    ...mapGetters(['isMobile']),

    hasWhitelabel () {
      if (this.whitelabel.logo === undefined) {
        return false
      }

      return true
    },

    logo () {
      if (this.$store.state.isWhitelabelLoading) {
        return ''
      }

      if (this.whitelabel.logo === undefined) {
        return this.$assets.faviconWhite
      }

      return this.whitelabel.logo
    },

    appName () {
      if (this.$store.state.isWhitelabelLoading) {
        return ''
      }

      if (this.whitelabel.name === undefined) {
        return this.$i18n.t('app.title')
      }

      return this.whitelabel.name
    },

    user () {
      return this.users.entity
    },

    drawer: {
      get () {
        return this.$store.state.drawer
      },

      set (value) {
        this.$store.commit('setDrawer', value)
      }
    },

    links () {
      if (this.user.__typename === 'Customer') {
        return [
          {
            title: this.$i18n.t('headers.home.operators'),
            path: '/System/Home',
            icon: 'mdi-home' 
          },
          {
            title: this.$i18n.t('headers.transports'),
            path: '/System/Transports',
            icon: 'mdi-badge-account' 
          },
          {
            title: this.$i18n.t('headers.units'),
            path: '/System/Units',
            icon: 'mdi-car' 
          },
          {
            title: this.$i18n.t('headers.groups'),
            path: '/System/Groups',
            icon: 'mdi-car-multiple' 
          },
          {
            title: this.$i18n.t('headers.services'),
            path: '/System/Services',
            icon: 'mdi-access-point' 
          }
        ]
      } else if (this.user.__typename === 'Operator') {
        const links = []
        links.push({
          title: this.$i18n.t('headers.home.operators'),
          path: '/System/Home',
          icon: 'mdi-home' 
        })
        // if (this.user.permission.transports > 0) {
        //   links.push({
        //     title: this.$i18n.t('headers.transports'),
        //     path: '/System/Transports',
        //     icon: 'mdi-account-badge' 
        //   })
        // }
        if (this.user.permission.fleets > 0) {
          links.push({
            title: this.$i18n.t('headers.units'),
            path: '/System/Units',
            icon: 'mdi-car' 
          },
          {
            title: this.$i18n.t('headers.groups'),
            path: '/System/Groups',
            icon: 'mdi-car-multiple' 
          })
        }
        if (this.user.permission.services > 0) {
          links.push({
            title: this.$i18n.t('headers.services'),
            path: '/System/Services',
            icon: 'mdi-access-point' 
          })
        }
        return links
      }

      return [
        {
          title: this.$i18n.t('pages.home.team'),
          path: '/Team/Home',
          icon: 'mdi-view-dashboard' 
        }
      ]      
    }
  },

  created () {
    // console.log(this.avatar, this.fullName)
  }
}
</script>