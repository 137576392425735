export default {
  version (state) {
    return `${state.version.local.version}-${state.version.local.branch}`
  },

  color (state) {
    return state.isDark ? 'white' : 'primary'
  },

  isMobile (state) {
    return state.width.full <= 930
  }
}