<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="6"
        offset-md="6"
        class="pa-2 d-flex align-center justify-end"
      >
        <v-btn icon class="mr-3" @click="toggleSearch = !toggleSearch">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          :ripple="false"
          depressed
          color="primary"
          @click="openDialog"
        >
          <v-icon class="mr-3">mdi-plus-circle</v-icon>
          {{ $t("actions.create") }}
        </v-btn>
      </v-col>
      <v-col v-if="toggleSearch" cols="12" class="pa-2 pt-0">
        <v-text-field
          v-model="search"
          :label="$t('actions.search')"
          hide-details
          width="100%"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="pa-2 d-flex flex-column">
        <v-card height="100%" class="d-flex flex-column elevation-3 pa-2">
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :custom-filter="accountsFilter"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :no-data-text="$t('helpers.noData')"
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn small icon>
                <v-icon small @click="alterAction('show', item.id)">
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn small icon>
                <v-icon small @click="alterAction('edit', item.id)">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn small icon>
                <v-icon small @click="alterAction('delete', item.id)">
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-spacer />
          <div class="text-center ml-auto pt-2">
            <v-pagination v-model="page" circle :length="pageCount" />
          </div>
        </v-card>
      </v-col>
    </v-row>

    <account-dialog
      v-model="object"
      :show-dialog="dialog"
      :readonly="!isEditting"
      @toggle-dialog="(state) => (dialog = state)"
      @set-readonly="(state) => (isEditting = state)"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { Dialog as AccountDialog } from 'Components/accounts'
import list from 'GraphQL/queries/accounts/list.gql'
import deleteMutation from 'GraphQL/mutations/accounts/delete.gql'
import { filters } from '@/mixins'

const dayjs = require('dayjs')
require('dayjs/locale/es')
dayjs.locale('es')

export default {
  components: {
    AccountDialog
  },
  mixins: [filters],
  data () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      isEditting: false,
      object: {},
      dialog: false,
      search: '',
      toggleSearch: false
    }
  },
  computed: {
    ...mapState(['isDark', 'accounts', 'users']),

    headers () {
      return [
        {
          text: this.$i18n.t('accounts.name'),
          align: 'center',
          sortable: true,
          value: 'name'
        },
        { text: '', value: 'actions', sortable: false, align: 'end' }
      ]
    },

    data () {
      return this.accounts.list
    }
  },
  created () {
    this.object = Object.assign({}, this.$store.getters['accounts/defaultObject'])
  },
  methods: {
    openDialog () {
      this.dialog = true
      this.isEditting = true
      this.object = Object.assign(
        {},
        this.$store.getters['accounts/defaultObject']
      )
    },
    evaluateResponse ({ status, errors, result, action }) {
      this.isLoading = false
      console.log(action)
      switch (status) {
        case 'OK':
          if (action !== 'delete') {
            this.object = result[0]
            this.dialog = true
          } else {
            this.$store.dispatch('accounts/getList', true)
          }
          break
        case 'UNPROCESSABLE':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'BADREQUEST':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'ACCESSDENIED':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.accessdenied')
          })
          break
      }
    },
    getDetail (id, action) {
      console.log(id)
      this.$apollo
        .query({
          query: list,
          variables: {
            token: this.users.entity.token,
            id: id
          },
          fetchPolicy: 'no-cache'
        })
        .then((response) => {
          const { status, errors, result } = response.data.accounts
          this.evaluateResponse({ status, errors, result, action })
        })
        .catch((err) => {
          console.log('Error handled', err)
          this.$store.commit('toggleSnackbar', {
            color: 'red darken-2',
            message: this.$t('helpers.errors.disaster')
          })
        })
    },

    deleteItem (id, action) {
      this.$apollo
        .mutate({
          mutation: deleteMutation,
          variables: {
            token: this.users.entity.token,
            ids: [id]
          },
          fetchPolicy: 'no-cache'
        })
        .then((response) => {
          const { status, errors, result } = response.data.deleteAccounts
          console.log('sadasd', action)
          this.evaluateResponse({ status, errors, result, action })
        })
        .catch((err) => {
          console.log('Error handled', err)
          this.$store.commit('toggleSnackbar', {
            color: 'red darken-2',
            message: this.$t('helpers.errors.disaster')
          })
        })
    },

    alterAction (action, id) {
      if (action === 'show') {
        this.isEditting = false
        this.getDetail(id, action)
      } else if (action === 'edit') {
        this.isEditting = true
        this.getDetail(id, action)
      } else {
        this.deleteItem(id, action)
      }
    }
  }
}
</script>
