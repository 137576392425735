<template>  
  <div>
    <v-dialog
      v-model="dialog"
      width="550px"
    >
      <v-card>
        <v-card-title class="mb-0 pt-4 pb-2 text-center">
          <p class="pa-0 ma-0 ml-2">{{ $t('devices.import.title') }}</p>
        </v-card-title>

        <v-divider class="mx-4" />

        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="12">
              <v-file-input
                :label="$t('devices.fileUpload')"
                accept=".xls,.xlsx"
                :color="color"
                :error="hasErrors('file')"
                :error-messages="getErrors('file')"
                outlined
                hide-details
                @change="convertToBase64"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mx-4" />

        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            color="red darken-2"
            text
            icon
            @click="discardItem"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
          <v-btn
            color="green darken-2"
            text
            icon
            @click="uploadFile"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="importErrorDialog"
      width="550px"
    >
      <v-card>
        <v-card-title class="mb-0 pt-4 pb-2 text-center">
          <p class="pa-0 ma-0 ml-2">{{ $t('devices.import.error') }}</p>
        </v-card-title>

        <v-divider class="mx-4" />

        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="12">
              <span>
                Los siguientes dispositivos no pudieron importarse por error en el modelo de hardware,
                asegúrese esté escrito correctamente.
              </span>
            </v-col>
            <v-col cols="12">
              <span v-for="value in devicesNotSaved" :key="value">
                {{ value }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mx-4" />

        <v-card-actions class="d-flex justify-space-between">
          <v-spacer/>
          <v-btn
            color="green darken-2"
            text
            icon
            @click="closeErrorDialog"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { orm } from '@/mixins'
import importMutation from '@/graphql/mutations/devices/import.gql'

export default {
  mixins: [orm],

  props: {
    showDialog: { type: Boolean, default: () => false }
  },

  data () {
    return {
      errors: {},
      isLoading: false,
      file: null,
      importErrorDialog: false,
      devicesNotSaved: []
    }
  },

  computed: {
    ...mapState(['isDark', 'width', 'users']),
    ...mapGetters(['color', 'isMobile']),
    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    }
  },

  methods: {

    discardItem () {
      this.dialog = false
      this.resetErrors()
    },

    closeErrorDialog () {
      this.importErrorDialog = false
    },

    convertToBase64 (file) {
      if (!file) {
        this.file = null
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const result = reader.result
        /**
         * Base64 has ~33% size increase compare to its original
         * decoded size
         *
         * to get the original decoded size we must multiply by
         * ~0.7518796992
         * and we will get the size in bytes of the original file
         */
        const kilobytes = result.length * 0.7518796992 / 1000
        // Max size of ~1.5 MB
        if (kilobytes > 1500) {
          this.$store.commit('toggleSnackbar', {
            message: this.$t('helpers.errors.file_size_too_big'),
            color: 'orange darken-2'
          })
          console.warn('File size too big with kilobytes: ', kilobytes)
          return
        }

        if (
          result.startsWith('data:application/vnd.ms-excel') ||
          result.startsWith('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        ) {
          this.file = result
        } else {
          this.$store.commit('toggleSnackbar', {
            message: this.$t('helpers.errors.invalidFormat'),
            color: 'orange darken-2'
          })
        }
      }
    },

    async uploadFile () {
      this.isLoading = true
      this.resetErrors()

      await this.$apollo.mutate({
        mutation: importMutation,
        variables: {
          token: this.users.entity.token,
          file: this.file
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.importDevices
        switch (status) {
          case 'OK':
            this.dialog = false
            this.$store.dispatch('devices/getList', true)

            if (result.notSaved.length > 0) {
              this.devicesNotSaved = result.notSaved
              this.importErrorDialog = true
            }

            this.$store.commit('toggleSnackbar', {
              color: 'green darken-2',
              message: this.$t('helpers.import.success')
            })
            break
          case 'UNPROCESSABLE':
            this.errors = this.parseErrors(errors)

            this.$store.commit('toggleSnackbar', {
              color: 'orange darken-2',
              message: this.$t('errors.import.invalid')
            })
            break
          case 'BADREQUEST':
            this.errors = this.parseErrors(errors)

            this.$store.commit('toggleSnackbar', {
              color: 'orange darken-2',
              message: this.$t('errors.invalidFields')
            })
            break
          case 'LIMITREACHED':
            this.$store.commit('toggleSnackbar', {
              color: 'orange darken-2',
              message: this.$t('errors.limit_reached')
            })
            break
        }
      }).catch(err => {
        console.log('[AssetsFile] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          message: this.$t('helpers.errors.disaster'),
          color: 'red darken-2'
        })
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
