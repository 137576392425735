<template>
  <v-container fluid>
    <v-layout row wrap class="px-8 mt-5">
      <h1>{{ $t("pages.pod.configuration") }}</h1>
      <v-flex xs12>
        <v-divider width="600" class="my-2" />
      </v-flex>

      <v-flex xs12 class="pa-2">
        <v-card>
          <v-card-text>
            <v-layout row wrap class="px-4">
              <v-flex xs12 class="px-2 my-5">
                <h4>{{ $t("pod.configuration.categories.auth") }}</h4>
                <v-divider width="400" />
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  v-model="object.username"
                  :label="$t('pod.configuration.fields.username')"
                  :placeholder="$t('pod.configuration.hints.username')"
                  :color="color"
                  :errors="errors.username.length > 0"
                  :error-messages="errors.username"
                  outlined
                />
              </v-flex>

              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  v-model="object.password"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('pod.configuration.fields.password')"
                  :placeholder="$t('pod.configuration.hints.password')"
                  :color="color"
                  :errors="errors.password.length > 0"
                  :error-messages="errors.password"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="showPassword = !showPassword"
                  outlined
                />
              </v-flex>

              <v-flex xs12 class="px-2 d-flex justify-end">
                <v-btn :disabled="isLoading" depressed color="primary" @click="submit">
                  {{ $t('actions.save') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import gql from 'graphql-tag'

export default {
  data () {
    return {
      object: {},
      rawErrors: {},
      showPassword: false,
      isLoading: false,
      randomNumber: 0
    }
  },

  computed: {
    ...mapState(['users']),
    ...mapGetters(['color']),

    defaultObject () {
      if (this.users.entity.loaded) {
        return {
          id: this.users.entity.configuration.id,
          username: this.users.entity.configuration.username,
          password: this.users.entity.configuration.password
        }
      }
      return {
        id: null,
        username: '',
        password: ''
      }
    },

    errors () {
      const errors = {}

      for (const key in this.defaultObject) {
        let keyCamel = key
        if (key.includes(' ')) {
          keyCamel = this.convertToCamelCase(key)
        }

        if (this.rawErrors[key] === undefined) {
          errors[keyCamel] = ''
        } else {
          errors[keyCamel] = this.rawErrors[key].join(', ')
        }
      }

      return errors
    }
  },

  watch: {
    defaultObject (newVal, oldVal) {
      this.object = Object.assign({ password: '' }, newVal)
    }
  },

  beforeMount () {
    this.object = Object.assign({ password: '' }, this.defaultObject)
  },

  methods: {
    convertToCamelCase (str) {
      str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, (match) => {
        return match.charAt(match.length - 1).toUpperCase()
      })
      return str.charAt(0).toLowerCase() + str.substring(1)
    },

    submit () {
      this.isLoading = true
      this.rawErrors = {}
      this.$apollo.mutate({
        mutation: gql`
          mutation ($data: ConfigurationInput!, $token: String!) {
            editConfiguration(token: $token, data: $data) {
              status
              errors
              result {
                id
                username
                password
              }
            }
          }
        `,
        variables: {
          data: this.object,
          token: this.users.entity.token
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        this.isLoading = false
        const { status, errors } = response.data.editConfiguration
        const rawErrors = {}

        switch (status) {
          case 'OK':
            // this.object = Object.assign({}, this.defaultObject)
            this.$store.dispatch('users/loadEntity', true)
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('pod.configuration.saved'),
              color: 'green darken-2',
              duration: 10000
            })
            // this.$router.push('/Home')
            break
          case 'UNPROCESSABLE':
            this.$store.commit('toggleSnackbar', {
              mesasge: this.$i18n.t('errors.invalidFields'),
              color: 'orange darken-2'
            })

            for (const key in errors) {
              rawErrors[this.convertToCamelCase(key)] = errors[key]
            }
            this.rawErrors = rawErrors
            break
          default:
            this.$store.commit('toggleSnackbar')
            break
        }
      }).catch((err) => {
        this.isLoading = false
        console.log(err)
        this.$store.commit('toggleSnackbar')
      })
    }
  }
}
</script>
