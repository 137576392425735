<template>
  <v-container fill-height fluid pa-0 class="secondary">
    <div :style="styles">
      <v-container fill-height fluid class="pa-0">
        <v-row align="center" justify="center">
          <div
            v-if="hasWhitelabel"
            :style="`position: fixed; top: 0; left: 0; background: rgba(${isDark ? '0,0,0' : '255,255,255'},0.7); width: 100vw; height: 100vh;`"
          />

          <transition name="component-fade" mode="out-in">
            <router-view />
          </transition>
        </v-row>

        <gm-footer color="transparent" font-color="white" />
      </v-container>
    </div>
  </v-container>
</template>

<script>
import { Footer } from 'Components/layout'
import { mapState } from 'vuex'

export default {
  components: {
    'gm-footer': Footer
  },

  data () {
    return {
      fab: false
    }
  },

  computed: {
    ...mapState(['whitelabel', 'isDark']),

    hasWhitelabel () {
      return this.whitelabel.background !== undefined
    },

    background () {
      if (this.whitelabel.background === undefined) {
        return ''
      }

      return this.whitelabel.background
    },

    styles () {
      return `width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.0); background-image: url(${this.background}); background-size: cover; background-position: center;`
    }
  }
}
</script>

<style>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>