export default {
  defaultObject () {
    return {
      id: undefined,
      name: '',
      companyName: '',
      url: '',
      primaryColor: '',
      secondaryColor: '',
      accentColor: '',
      background: null,
      logo: null,
      usersQrCode: null
    }
  }
}