/*
  Charts actions
*/
// GraphQL initializer
// graphql files
// Libraries

import accountChart from 'GraphQL/queries/charts/accountChart.gql'
import unitChart from 'GraphQL/queries/charts/unitChart.gql'
import { apollo } from '@/plugins/apollo'

export default {
  async getAccountChartData ({ state, dispatch, commit, rootState }, payload = false) {
    const variables = {
      token: rootState.users.entity.token
    }
    if (payload) {
      commit('setLoading', true, { root: true })
      variables.startAt = payload.startAt
      variables.endAt = payload.endAt
    }

    await apollo.query({
      query: accountChart,
      variables,
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, result } = response.data.accountChart

      switch (status) {
        case 'OK':
          commit('setAccountChartData', result)
          break

        case 'UNPROCESSABLE':
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      if (payload) {
        commit('setLoading', false, { root: true })
      }
    })
  },

  async getUnitChartData ({ state, dispatch, commit, rootState }, payload = false) {
    const variables = {
      token: rootState.users.entity.token
    }
    if (payload) {
      commit('setLoading', true, { root: true })
      variables.startAt = payload.startAt
      variables.endAt = payload.endAt
    }

    await apollo.query({
      query: unitChart,
      variables,
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, result } = response.data.unitChart

      switch (status) {
        case 'OK':
          commit('setUnitChartData', result)
          break

        case 'UNPROCESSABLE':
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      if (payload) {
        commit('setLoading', false, { root: true })
      }
    })
  }
}
