<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col
        cols="12"
        class="pa-2"
      >
        <v-card>
          <v-container fluid class="pa-2">
            <v-row no-gutters>
              <v-col cols="12" sm="5" class="pa-2 d-flex flex-column justify-center">
                <v-dialog
                  ref="startAtDialog"
                  v-model="startAtDialog"
                  :return-value.sync="startAt"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startAt"
                      :label="$t('helpers.startAt')"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startAt"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="startAtDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startAtDialog.save(startAt)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="5" class="pa-2 d-flex flex-column justify-center">
                <v-dialog
                  ref="endAtDialog"
                  v-model="endAtDialog"
                  :return-value.sync="endAt"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endAt"
                      :label="$t('helpers.endAt')"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endAt"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="endAtDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.endAtDialog.save(endAt)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="2" class="pa-2 d-flex flex-column justify-center">
                <v-btn height="56" :disabled="!validateButton" color="primary" @click="generateCharts()">
                  <v-icon class="mr-1">mdi-magnify</v-icon>
                  {{ $t('actions.find') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="6" class="pa-2">
        <v-card>
          <v-card-title>
            <!-- Account Creation per day in the last week -->
            {{ $t('home.charts.accountCreation') }}
          </v-card-title>
          <v-card-text v-if="accountChartLoaded">
            <div id="chart">
              <apexchart
                :key="`chart1-${isDark}`"
                type="line"
                height="350"
                width="100%"
                :options="accountChartOptions"
                :series="accountSeries"
              />
            </div>
          </v-card-text>
          <v-card-text v-else>
            <v-sheet height="365" class="d-flex flex-column align-center justify-center">
              <v-icon x-large>mdi-coffee</v-icon>
              <h4>
                {{ $t('helpers.noData') }}
              </h4>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="pa-2">
        <v-card>
          <v-card-title>
            <!-- Units added per day in the last week -->
            {{ $t('home.charts.unitsAdded') }}
          </v-card-title>
          <v-card-text v-if="unitChartLoaded">
            <div id="unitChart">
              <apexchart
                :key="`chart2-${isDark}`"
                type="line"
                height="350"
                width="100%"
                :options="unitChartOptions"
                :series="unitSeries"
              />
            </div>
          </v-card-text>
          <v-card-text v-else>
            <v-sheet height="365" class="d-flex flex-column align-center justify-center">
              <v-icon x-large>mdi-coffee</v-icon>
              <h4>
                {{ $t('helpers.noData') }}
              </h4>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  data () {
    return {
      // series: [
      //   {
      //     name: '',
      //     data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      //   }
      // ],
      accountSeries: [],
      unitSeries: [],
      accountChartLoaded: false,
      unitChartLoaded: false,
      startAtDialog: false,
      endAtDialog: false,
      startAt: '',
      endAt: ''
    }
  },
  computed: {
    ...mapState(['isDark']),

    accountChartItems () { return this.$store.state.charts.accountChartData },
    unitChartItems () { return this.$store.state.charts.unitChartData },
    accountCategories () {
      return this.accountChartItems.map(el => { return el.date })
    },
    unitCategories () {
      return this.unitChartItems.map(el => { return el.date })
    },

    validateButton () {
      if (this.startAt === '') {
        return false
      }
      if (this.endAt === '') {
        return false
      }
      return true
    },

    accountChartOptions () {
      return {
        theme: {
          mode: this.isDark ? 'dark' : 'light'
        },
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            // colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            // opacity: 0.5
          }
        },
        xaxis: {
          categories: this.accountCategories
        }
      }
    },

    unitChartOptions () {
      return {
        theme: {
          mode: this.isDark ? 'dark' : 'light'
        },
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            // colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            // opacity: 0.5
          }
        },
        xaxis: {
          categories: this.unitCategories
        }
      }
    }
  },

  watch: {
    accountChartItems (v) {
      this.accountChartLoaded = false
      this.accountSeries = [{
        name: this.$i18n.t('home.charts.accountCreation'),
        data: v.map(el => { return el.count })
      }]
      setTimeout(() => {
        this.accountChartLoaded = true
      }, 0)
    },

    unitChartItems (v) {
      this.unitChartLoaded = false
      this.unitSeries = [{
        name: this.$i18n.t('home.charts.unitsAdded'),
        data: v.map(el => { return el.count })
      }]
      setTimeout(() => {
        this.unitChartLoaded = true
      }, 0)
    }
  },

  mounted () {
    if (this.accountChartItems.length !== 0) {
      this.accountChartLoaded = false
      this.accountSeries = [{
        name: this.$i18n.t('home.charts.accountCreation'),
        data: this.accountChartItems.map(el => { return el.count })
      }]
      setTimeout(() => {
        this.accountChartLoaded = true
      }, 0)
    }

    if (this.unitChartItems.length !== 0) {
      this.unitChartLoaded = false
      this.unitSeries = [{
        name: this.$i18n.t('home.charts.unitsAdded'),
        data: this.unitChartItems.map(el => { return el.count })
      }]
      setTimeout(() => {
        this.unitChartLoaded = true
      }, 0)
    }
  },

  methods: {
    async generateCharts () {
      this.accountChartLoaded = false
      this.unitChartLoaded = false
      const variables = {
        startAt: dayjs(this.startAt).unix(),
        endAt: dayjs(this.endAt).unix()
      }
      console.log('variables :>> ', variables)
      await this.$store.dispatch('charts/getAccountChartData', variables)
      await this.$store.dispatch('charts/getUnitChartData', variables)

      this.accountChartLoaded = true
      this.unitChartLoaded = true
    }
    // categories () {
    //   const dayArray = []

    //   for (let i = 7; i > 0; i--) {
    //     console.log(i)
    //     const currentDate = new Date().getTime() - i * 1000 * 86400
    //     console.log(currentDate)
    //     dayArray.push(dayjs(currentDate).format('ddd'))
    //   }

    //   return dayArray
    // }
  }
}
</script>
