var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2 d-flex align-center justify-end",attrs:{"cols":"12","md":"6","offset-md":"6"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"icon":""},on:{"click":function($event){_vm.toggleSearch = !_vm.toggleSearch}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),(_vm.toggleSearch)?_c('v-col',{staticClass:"pa-2 pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('actions.search'),"hide-details":"","width":"100%"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2 d-flex flex-column",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"d-flex flex-column elevation-3 pa-2",attrs:{"height":"100%"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"custom-filter":_vm.whitelabelsFilter,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","no-data-text":_vm.$t('helpers.noData')},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.showQrCode(item.id)}}},[_vm._v(" mdi-qrcode-scan ")])],1)]}}],null,true)}),_c('v-spacer'),_c('div',{staticClass:"text-center ml-auto pt-2"},[_c('v-pagination',{attrs:{"circle":"","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"50vh","value":_vm.qrDialog},on:{"click:outside":function($event){_vm.qrDialog = false}}},[_c('v-card',{attrs:{"width":"50vh"}},[_c('v-card-title',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.downloadQrCode(_vm.object.customersQrCode)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('actions.download')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.copyQrCode(_vm.object.customersQrCode)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('actions.copyToClipboard')))])])],1),_c('v-card-text',[_c('v-img',{attrs:{"width":"100%","height":"100%","src":_vm.object.usersQrCode}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }