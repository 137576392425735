export default {
  defaultObject () {
    return {
      id: undefined,
      name: '',
      ident: '',
      phoneNumber: '',
      hardwareModelId: undefined,
      iccid: ''
    }
  }
}