import users from './users'
import languages from './languages'
import customers from './customers'
import accounts from './accounts'
import dealers from './dealers'
import devices from './devices'
import wialonUsers from './wialonUsers'
import whitelabels from './whitelabels'
import charts from './charts'

export default {
  users,
  languages,
  customers,
  accounts,
  dealers,
  devices,
  wialonUsers,
  whitelabels,
  charts
}