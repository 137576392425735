<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="isMobile"
    scrollable
    width="500"
    :max-width="width.dialog"
  >
    <v-card>
      <v-card-title class="d-flex align-start justify-space-between">
        <p>{{ title }}</p>
        <v-btn icon @click="discardItem()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="better-scrollbar">
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="12" md="6" class="pa-2">
              <v-text-field
                v-model="object.name"
                :disabled="!isEditting"
                :label="$t('accounts.name')"
                :error="hasErrors('name')"
                :error-messages="getErrors('name')"
                outlined
                :color="color"
                @focus="$emit('set-field', { field: 'name', state: true })"
                @blur="$emit('set-field', { field: 'name', state: false })"
              />
            </v-col>
            <v-col cols="12" md="6" class="pa-2">
              <v-switch
                v-model="object.isMain"
                inset
                :disabled="!isEditting"
                :label="$t('accounts.isMain')"
                :error="hasErrors('isMain')"
                :error-messages="getErrors('isMain')"
                @focus="$emit('set-field', { field: 'isMain', state: true })"
                @blur="$emit('set-field', { field: 'isMain', state: false })"
              />
            </v-col>
            <v-col cols="12" class="pa-2">
              <v-text-field
                v-model="object.token"
                :disabled="!isEditting"
                :error="hasErrors('token')"
                :error-messages="getErrors('token')"
                :label="$t(`accounts.token`)"
                :placeholder="$t('accounts.generateToken.helper')"
                :color="color"
                readonly
                outlined
              >
                <template v-if="isEditting" v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn style="margin-top: -4px" small icon v-on="on" @click="generateToken">
                        <v-icon>mdi-{{ object.token.length === 0 ? 'key-plus' : 'refresh' }}</v-icon>
                      </v-btn>
                    </template>

                    <span>{{ $t('accounts.generateToken.message') }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          v-if="isEditting"
          :ripple="false"
          outlined
          dark
          color="primary"
          @click="discardItem"
        >
          {{ $t('actions.discard') }}
        </v-btn>
        <v-btn
          v-if="isEditting"
          :ripple="false"
          depressed
          color="primary"
          @click="saveItem(object.id)"
        >
          {{ $t('actions.save') }}
        </v-btn>
        <v-btn
          v-if="!isEditting"
          :ripple="false"
          depressed
          color="orange darken-1 white--text"
          @click="isEditting = true"
        >
          {{ $t('actions.edit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { orm } from '@/mixins'
import addMutation from '@/graphql/mutations/accounts/add.gql'
import editMutation from '@/graphql/mutations/accounts/edit.gql'

export default {

  mixins: [orm],
  model: {
    prop: 'data',
    event: 'set-object'
  },

  props: {
    showDialog: { type: Boolean, default: () => false },
    data: { type: Object, default: () => {} },
    readonly: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      show: false,
      errors: {}
    }
  },

  computed: {
    ...mapState(['isDark', 'accounts', 'workshops', 'users', 'width']),
    ...mapGetters(['color', 'isMobile']),

    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    },

    isEditting: {
      get () {
        return !this.readonly
      },

      set (value) {
        this.$emit('set-readonly', value)
      }
    },

    title () {
      return this.object.id === null ? this.$i18n.t('accounts.new') : this.$i18n.t('accounts.edit')
    },

    baseUrl () {
      return 'https://hosting.wialon.com'
    }
  },

  watch: {},

  created () {
    console.log('hola')
  },

  methods: {
    async saveItem (id) {
      this.isLoading = true
      const data = {
        id: id,
        name: this.object.name,
        token: this.object.token,
        isMain: this.object.isMain
      }
      if (id) {
        await this.edit(data)
      } else {
        await this.add(data)
      }
    },

    evaluateResponse ({ status, errors, result, action }) {
      this.isLoading = false
      console.log(status, errors)
      switch (status) {
        case 'OK':
          this.discardItem()
          this.resetErrors()
          this.$store.commit('toggleSnackbar', {
            color: 'green darken-2',
            message: this.$t('helpers.saved.successfully')
          })
          if (action === 'add' || action === 'edit') {
            this.$store.dispatch('accounts/getList', true)
          }
          break
        case 'UNPROCESSABLE':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'BADREQUEST':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'LIMITREACHED':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.limit_reached')
          })
          break
      }
    },

    add (data) {
      const action = 'add'
      this.$apollo.mutate({
        mutation: addMutation,
        variables: {
          token: this.users.entity.token,
          data
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.addAccount
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('[Devices Dialog] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    edit (data) {
      const action = 'edit'
      this.$apollo.mutate({
        mutation: editMutation,
        variables: {
          token: this.users.entity.token,
          data
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.editAccount
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('[Devices Dialog] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    discardItem () {
      this.dialog = false
      this.object = Object.assign({}, this.$store.getters['account/defaultObject'])
      this.resetErrors()
    },

    generateToken () {
      const url = `${this.baseUrl}/login.html?client_id=Golden M&access_type=-1&activation_time=0&duration=0&flags=0x1&redirect_uri=${this.baseUrl}/post_token.html`
      console.log(url)
      window.addEventListener('message', this.tokenReceived)
      window.open(url, '_blank', 'width=760, height=500, top=300, left=500')
    },

    tokenReceived (e) {
      const msg = e.data
      console.log(msg)
      if (typeof msg === 'string' && msg.indexOf('access_token=') >= 0) {
        this.object.token = msg.replace('access_token=', '')
        window.removeEventListener('message', this.tokenReceived)

        const instance = window.wialon.core.Session.getInstance()
        instance.initSession('https://hst-api.wialon.com')
        instance.loginToken(this.object.token, '')

        const user = instance.getCurrUser()
        this.object.username = user.getName()
      }
    }
  }
}
</script>
