<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col
        cols="12"
        class="pa-2 d-flex align-center"
      >
        <v-btn
          :ripple="false"
          depressed
          :outlined="toggleIsSold !== 'AVAILABLE'"
          color="primary"
          class="mr-3"
          @click="setIsSoldFilter('AVAILABLE')"
        >
          {{ $t('devices.available') }}
          <v-chip small color="grey lighten-2 grey--text text--darken-4" class="ml-1">{{ totalAvailables }}</v-chip>
        </v-btn>
        <v-btn
          :ripple="false"
          depressed
          color="primary"
          :outlined="toggleIsSold !== 'NOTAVAILABLE'"
          class="mr-3"
          @click="setIsSoldFilter('NOTAVAILABLE')"
        >
          {{ $t('devices.notAvailable') }}
          <v-chip small color="grey lighten-2 grey--text text--darken-4" class="ml-1">{{ totalNotAvailables }}</v-chip>
        </v-btn>
        <v-spacer />
        <v-btn icon class="mr-3" @click="toggleSearch = !toggleSearch">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          :ripple="false"
          depressed
          color="primary"
          @click="openDialog"
        >
          <v-icon class="mr-3">mdi-plus-circle</v-icon>
          {{ $t("actions.create") }}
        </v-btn>
        <v-btn
          class="ml-2"
          :ripple="false"
          depressed
          color="primary"
          @click="openImportDialog"
        >
          <v-icon class="mr-3">mdi-upload</v-icon>
          {{ $t("actions.import.devices") }}
        </v-btn>
        <v-btn
          class="ml-2"
          :ripple="false"
          depressed
          color="primary"
          @click="downloadBaseFile"
        >
          <v-icon class="mr-3">mdi-file-download</v-icon>
          {{ $t("actions.export.fileExample") }}
        </v-btn>
      </v-col>
      <v-col v-if="toggleSearch" cols="12" class="pa-2 pt-0">
        <v-text-field
          v-model="search"
          :label="$t('actions.search')"
          hide-details
          width="100%"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="pa-2 d-flex flex-column">
        <v-card height="100%" class="d-flex flex-column elevation-3 pa-2">
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :custom-filter="devicesFilter"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :no-data-text="$t('helpers.noData')"
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.isSold`]="{ item }">
              <div v-if="!item.isSold" class="d-flex align-center">
                <v-icon class="mr-2" color="green">mdi-check-circle</v-icon>
                <span>{{ $t('devices.available') }}</span>
              </div>
              <div v-else class="d-flex align-center">
                <v-icon class="mr-2" color="red">mdi-cancel</v-icon>
                <span>{{ $t('devices.notAvailable') }}</span>
                <!-- <span>{{ $t('devices.sold') + ' ' + formatDate(item.soldAt) }}</span> -->
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn small icon>
                <v-icon small @click="alterAction('show', item.id)">
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn small icon>
                <v-icon small @click="alterAction('edit', item.id)">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn small icon>
                <v-icon small @click="confirmDelete = true, deleteId = item.id">
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-spacer />
          <div class="text-center ml-auto pt-2">
            <v-pagination v-model="page" circle :length="pageCount" />
          </div>
        </v-card>
      </v-col>
    </v-row>

    <device-dialog
      v-model="object"
      :show-dialog="dialog"
      :readonly="!isEditting"
      @toggle-dialog="(state) => (dialog = state)"
      @set-readonly="(state) => (isEditting = state)"
    />
    <file-dialog
      :show-dialog="fileDialog"
      @toggle-dialog="(state) => (fileDialog = state)"
    />
    <confirm-dialog
      :dialog="confirmDelete"
      type="delete"
      :width="550"
      @toggle-dialog="(state) => (confirmDelete = state)"
      @action="alterAction('delete', deleteId)"
      @cancel-dialog="confirmDelete = false"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { Dialog as DeviceDialog, FileDialog } from 'Components/devices'
import { ConfirmDialog } from 'Components/utils'
import list from 'GraphQL/queries/devices/list.gql'
import deleteMutation from 'GraphQL/mutations/devices/delete.gql'
import { filters } from '@/mixins'

const dayjs = require('dayjs')
require('dayjs/locale/es')
dayjs.locale('es')

export default {
  components: {
    DeviceDialog,
    FileDialog,
    ConfirmDialog
  },
  mixins: [filters],
  data () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      isEditting: false,
      object: {},
      dialog: false,
      fileDialog: false,
      search: '',
      toggleSearch: false,
      confirmDelete: false,
      deleteId: undefined,
      toggleIsSold: 'ALL'
    }
  },
  computed: {
    ...mapState(['isDark', 'devices', 'users']),

    headers () {
      return [
        {
          text: '',
          align: 'center',
          sortable: true,
          value: 'isSold'
        },
        // {
        //   text: this.$i18n.t('devices.name'),
        //   align: 'center',
        //   sortable: true,
        //   value: 'name'
        // },
        {
          text: this.$i18n.t('devices.ident'),
          align: 'center',
          sortable: true,
          value: 'ident'
        },
        {
          text: this.$i18n.t('devices.hardwareModel'),
          align: 'center',
          sortable: true,
          value: 'hardwareModel.name'
        },
        {
          text: this.$i18n.t('devices.phoneNumber'),
          align: 'center',
          sortable: true,
          value: 'phoneNumber'
        },
        { text: '', value: 'actions', sortable: false, align: 'end' }
      ]
    },

    totalAvailables () {
      return this.devices.list.filter(el => !el.isSold).length
    },

    totalNotAvailables () {
      return this.devices.list.filter(el => el.isSold).length
    },

    data () {
      if (this.toggleIsSold === 'AVAILABLE') {
        return this.devices.list.filter(el => !el.isSold)
      } else if (this.toggleIsSold === 'NOTAVAILABLE') {
        return this.devices.list.filter(el => el.isSold)
      }
      return this.devices.list
    }
  },
  created () {
    this.object = Object.assign({}, this.$store.getters['devices/defaultObject'])
  },
  methods: {
    formatDate (date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm:ssZ')
    },
    openDialog () {
      this.dialog = true
      this.isEditting = true
      this.object = Object.assign(
        {},
        this.$store.getters['devices/defaultObject']
      )
    },
    openImportDialog () {
      this.fileDialog = true
    },
    downloadBaseFile () {
      window.open(process.env.NODE_ENV === 'development' ? 'http://localhost:8000/static/base_import.xlsx' : 'https://api.wecommerce.goldenm.dev/static/base_import.xlsx', '_blank')
    },
    evaluateResponse ({ status, errors, result, action }) {
      this.isLoading = false
      switch (status) {
        case 'OK':
          if (action !== 'delete') {
            this.object = result[0]
            this.dialog = true
          } else {
            this.confirmDelete = false
            this.$store.dispatch('devices/getList', true)
          }
          break
        case 'UNPROCESSABLE':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'BADREQUEST':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'ACCESSDENIED':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.accessdenied')
          })
          break
      }
    },
    getDetail (id, action) {
      this.$apollo
        .query({
          query: list,
          variables: {
            token: this.users.entity.token,
            id: id
          },
          fetchPolicy: 'no-cache'
        })
        .then((response) => {
          const { status, errors, result } = response.data.devices
          this.evaluateResponse({ status, errors, result, action })
        })
        .catch((err) => {
          console.log('Error handled', err)
          this.$store.commit('toggleSnackbar', {
            color: 'red darken-2',
            message: this.$t('helpers.errors.disaster')
          })
        })
    },

    deleteItem (id, action) {
      this.$apollo
        .mutate({
          mutation: deleteMutation,
          variables: {
            token: this.users.entity.token,
            ids: [id]
          },
          fetchPolicy: 'no-cache'
        })
        .then((response) => {
          const { status, errors, result } = response.data.deleteDevices
          this.evaluateResponse({ status, errors, result, action })
        })
        .catch((err) => {
          console.log('Error handled', err)
          this.$store.commit('toggleSnackbar', {
            color: 'red darken-2',
            message: this.$t('helpers.errors.disaster')
          })
        })
    },

    alterAction (action, id) {
      if (action === 'show') {
        this.isEditting = false
        this.getDetail(id, action)
      } else if (action === 'edit') {
        this.isEditting = true
        this.getDetail(id, action)
      } else {
        this.deleteItem(id, action)
      }
    },

    setIsSoldFilter (value) {
      if (this.toggleIsSold === value) {
        this.toggleIsSold = 'ALL'
      } else {
        this.toggleIsSold = value
      }
    }
  }
}
</script>
