<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="isMobile"
    scrollable
    width="500"
    :max-width="width.dialog"
  >
    <v-card>
      <v-card-title class="d-flex align-start justify-space-between">
        <p>{{ title }}</p>
        <v-btn icon @click="discardItem()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="better-scrollbar">
        <v-container fluid>
          <v-row no-gutters>
            <!-- <v-col cols="12" md="6" class="pa-2">
              <v-text-field
                v-model="object.name"
                :disabled="!isEditting"
                :label="$t('devices.name')"
                :error="hasErrors('name')"
                :error-messages="getErrors('name')"
                outlined
                :color="color"
                @focus="$emit('set-field', { field: 'name', state: true })"
                @blur="$emit('set-field', { field: 'name', state: false })"
              />
            </v-col> -->
            <v-col cols="12" class="pa-2">
              <v-text-field
                v-model="object.ident"
                :disabled="!isEditting"
                :label="$t('devices.ident')"
                :error="hasErrors('ident')"
                :error-messages="getErrors('ident')"
                outlined
                :color="color"
                @focus="$emit('set-field', { field: 'ident', state: true })"
                @blur="$emit('set-field', { field: 'ident', state: false })"
              />
            </v-col>
            <v-col cols="12" md="6" class="pa-2">
              <v-text-field
                v-model="object.phoneNumber"
                :disabled="!isEditting"
                :label="$t('devices.phoneNumber')"
                :error="hasErrors('phoneNumber')"
                :error-messages="getErrors('phoneNumber')"
                outlined
                :color="color"
                @focus="$emit('set-field', { field: 'phoneNumber', state: true })"
                @blur="$emit('set-field', { field: 'phoneNumber', state: false })"
              />
            </v-col>
            <v-col cols="12" md="6" class="pa-2">
              <v-text-field
                v-model="object.iccid"
                :disabled="!isEditting"
                :label="$t('devices.iccid')"
                :error="hasErrors('iccid')"
                :error-messages="getErrors('iccid')"
                outlined
                :color="color"
                @focus="$emit('set-field', { field: 'iccid', state: true })"
                @blur="$emit('set-field', { field: 'iccid', state: false })"
              />
            </v-col>
            <v-col cols="12" class="pa-2">
              <v-autocomplete
                v-model="object.hardwareModelId"
                :items="hardwareModels"
                outlined
                item-text="name"
                item-value="id"
                :disabled="!isEditting"
                :label="$t('devices.hardwareModel')"
                :error="hasErrors('hardwareModelId')"
                :error-messages="getErrors('hardwareModelId')"
                @focus="$emit('set-field', { field: 'hardwareModelId', state: true })"
                @blur="$emit('set-field', { field: 'hardwareModelId', state: false })"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          v-if="isEditting"
          :ripple="false"
          outlined
          dark
          color="primary"
          @click="discardItem"
        >
          {{ $t('actions.discard') }}
        </v-btn>
        <v-btn
          v-if="isEditting"
          :ripple="false"
          depressed
          color="primary"
          @click="saveItem(object.id)"
        >
          {{ $t('actions.save') }}
        </v-btn>
        <v-btn
          v-if="!isEditting"
          :ripple="false"
          depressed
          color="orange darken-1 white--text"
          @click="isEditting = true"
        >
          {{ $t('actions.edit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { orm } from '@/mixins'
import addMutation from '@/graphql/mutations/devices/add.gql'
import editMutation from '@/graphql/mutations/devices/edit.gql'

export default {

  mixins: [orm],
  model: {
    prop: 'data',
    event: 'set-object'
  },

  props: {
    showDialog: { type: Boolean, default: () => false },
    data: { type: Object, default: () => {} },
    readonly: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      show: false,
      errors: {}
    }
  },

  computed: {
    ...mapState(['isDark', 'devices', 'workshops', 'users', 'width', 'hardwareModels']),
    ...mapGetters(['color', 'isMobile']),

    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    },

    isEditting: {
      get () {
        return !this.readonly
      },

      set (value) {
        this.$emit('set-readonly', value)
      }
    },

    title () {
      return this.object.id === undefined ? this.$i18n.t('devices.new') : this.$i18n.t('devices.edit')
    }
  },

  watch: {},

  created () {
    console.log('hola')
  },

  methods: {
    async saveItem (id) {
      this.isLoading = true
      const hardwareModel = this.hardwareModels.find(value => value.id === this.object.hardwareModelId)
      const data = {
        id: id,
        name: this.object.name,
        ident: this.object.ident,
        phoneNumber: this.object.phoneNumber,
        hardwareModel,
        iccid: this.object.iccid
      }
      if (id) {
        await this.edit(data)
      } else {
        await this.add(data)
      }
    },

    evaluateResponse ({ status, errors, result, action }) {
      this.isLoading = false
      console.log(status, errors)
      switch (status) {
        case 'OK':
          this.discardItem()
          this.resetErrors()
          this.$store.commit('toggleSnackbar', {
            color: 'green darken-2',
            message: this.$t('helpers.save.success')
          })
          if (action === 'add' || action === 'edit') {
            this.$store.dispatch('devices/getList', true)
          }
          break
        case 'UNPROCESSABLE':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'BADREQUEST':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'LIMITREACHED':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.limit_reached')
          })
          break
      }
    },

    add (data) {
      const action = 'add'
      this.$apollo.mutate({
        mutation: addMutation,
        variables: {
          token: this.users.entity.token,
          data
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.addDevice
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('[Devices Dialog] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    edit (data) {
      const action = 'edit'
      this.$apollo.mutate({
        mutation: editMutation,
        variables: {
          token: this.users.entity.token,
          data
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.editDevice
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('[Devices Dialog] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    discardItem () {
      this.dialog = false
      this.object = Object.assign({}, this.$store.getters['devices/defaultObject'])
      this.resetErrors()
    }
  }
}
</script>
