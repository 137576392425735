const assets = {}

assets.install = (Vue) => {
  Vue.prototype.$assets = {
    favicon: require('@/assets/favicon.png'),
    robot: require('@/assets/robot.png'),
    faviconWhite: require('@/assets/favicon-white.png')
    // background: require('@/assets/background.png')
  }
}

export default assets