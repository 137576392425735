export default {
  defaultObject () {
    return {
      id: undefined,
      name: '',
      email: '',
      username: '',
      token: null
    }
  }
}