<template>
  <v-dialog
    v-model="dialog"
    :max-width="width"
  >
    <v-card>
      <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t(`actions.${type === 'delete' ? 'deleteConfirmation' : 'toggleConfirmation'}`) }}</v-card-title>
      <v-card-actions style="text-align: center;">
        <v-spacer />
        <v-btn
          color="error"
          text
          icon
          @click="$emit('cancel-dialog')"
        >
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
        <v-btn
          color="success"
          text
          icon
          @click="$emit('action')"
        >
          <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'delete',
      validator (value) {
        if (value === '' || !['delete', 'toggle'].includes(value)) {
          return 'delete'
        }
        return value
      }
    },
    width: {
      type: Number,
      default: 330
    }
  }
}
</script>